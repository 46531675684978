@import 'sass';

.Nav {
	height: 100%;
	width: $padding-horizontal;
	position: fixed;
	right: 0;
	z-index: 102;
	opacity: 0;
	animation: appear 1s ease-in 0.5s 1 normal forwards;
	transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
	@include flex-center();

	@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
		display: none;
	}

	ul {
		@include flex-center;
		flex-direction: column;
		align-items: center;
		width: 100%;
		list-style: none;

		.List {
			margin: 40px;
			text-align: center;
			cursor: pointer;
			font-size: 28px;
			opacity: 0.8;
			letter-spacing: 1px;
			color: rgb(30, 30, 30);
			position: relative;

			.Icon {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
			}
			.IconCurrentHome {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
			}
			.IconCurrent {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				opacity: 0;
			}
		}
	}

	&MenuActive {
		@extend .Nav;
		display: none;
	}
}
