@import 'sass';

.ScrollTop {
	position: fixed;
	bottom: 10%;
	left: 45%;
	color: white;
	z-index: 10;
	@include flex-center;
	flex-direction: column;
	opacity: 0;
	animation: appear 1s 1s forwards;

	@media screen and (max-width: $breakpoint-tablet) {
		left: 40%;
	}
	@media screen and (max-height: 500px) {
		bottom: 10%;
		left: 5%;
	}

	p {
		transform: rotate(-90deg);
		font-weight: 300;
		font-size: 12px;
		margin-bottom: 15px;
	}

	.Arrow {
		@keyframes bounce {
			from {
				transform: translateY(0);
			}
			60% {
				transform: translateY(15px);
			}
			to {
				transform: translateY(0);
			}
		}
		animation: bounce 1.5s infinite;
	}

	&Hide {
		@extend .ScrollTop;
		opacity: 1;
		animation: disappear 0.5s forwards;
	}
}
