@keyframes disappear {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes appear {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes leftSlideIn {
	from {
		width: 100%;
	}
	to {
		width: 50%;
	}
}

@keyframes leftExpand {
	from {
		width: 50%;
	}
	to {
		width: 100%;
	}
}

@keyframes fadeInLift {
	from {
		opacity: 0;
		transform: translateY(50%);
	}
	to {
		opacity: 1;
		transform: translateY(0%);
	}
}

@keyframes fadeInSlideRight {
	from {
		opacity: 0;
		transform: translateX(-100%);
	}
	to {
		opacity: 1;
		transform: translateX(0%);
	}
}
@keyframes fadeInSlideLeft {
	from {
		opacity: 0;
		transform: translateX(100%);
	}
	to {
		opacity: 1;
		transform: translateX(0%);
	}
}

@keyframes slideDown {
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateY(0);
	}
}
@keyframes slideUp {
	from {
		transform: translateY(100%);
	}
	to {
		transform: translateY(0);
	}
}
@keyframes slideRight {
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0);
	}
}
@keyframes slideLeft {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateY(0);
	}
}
