@import 'sass';

.Portfolio {
	width: 100%;
	height: 315vh;
	position: absolute;
	overflow-x: hidden;
	background-color: $color-light;

	&Loading {
		@extend .Portfolio;
		height: 100vh;
	}

	@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
		height: fit-content;
		min-height: 100vh;
	}

	.OpenScreen {
		position: fixed;
		bottom: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: $color-coding;
		z-index: -9;
		@include flex-center;

		@keyframes ScreenSlideDown {
			from {
				height: 100%;
			}
			to {
				height: 0;
			}
		}

		&Loaded {
			@extend .OpenScreen;
			// @media screen and (min-width: $breakpoint-tablet) and (min-height: $breakpoint-tablet) {
			animation: ScreenSlideDown 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s 1 normal forwards;
			// }
		}
	}
	.LoadingPage {
		position: absolute;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		@include flex-center;
		opacity: 1;
		transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

		&Hide {
			@extend .LoadingPage;
			animation: fadeThenLeave 0.5s ease forwards;
			@keyframes fadeThenLeave {
				0% {
					opacity: 1;
					transform: translateX(0);
				}
				99% {
					opacity: 0;
					transform: translateX(0);
				}
				100% {
					opacity: 0;
					transform: translateX(-100vw);
				}
			}
		}
	}
	.Main {
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		opacity: 0;
		animation: fadeInLift 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

		@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
			width: 100%;
			height: fit-content;
			position: relative;
			padding-top: 7vh;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
		}

		&Loading {
			@extend .Main;
			display: none;
		}

		.Work {
			position: absolute;
			width: 100%;
			height: fit-content;
			top: 0;
			left: 0;
			transition: all 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
			// opacity: 0;
			// animation: fadeInLift 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

			@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
				position: relative;
				height: fit-content;
				width: 100%;
				justify-content: flex-start;
				top: 0;
				right: 0;
				padding: 10%;
			}

			.Title {
				position: absolute;
				left: 20%;
				top: 50vh;
				transform: translateY(-50%);
				opacity: 1;
				transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
				font-size: 120px;
				font-weight: normal;
				font-family: 'Aboreto';
				color: $color-headers;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;

				@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
					position: relative;
					margin-bottom: 40px;
					top: 0;
					left: 0;
					transform: none;
				}

				.Icon {
					fill: none;
					stroke: black;
					stroke-width: 4;
					stroke-dasharray: 300;
					stroke-dashoffset: 300;
					transform: scale(0.5);

					@keyframes dashoffset {
						from {
							stroke-dashoffset: 300;
						}
						to {
							stroke-dashoffset: 0;
						}
					}

					animation: dashoffset 1.5s ease 0.5s forwards;
				}

				.Header {
					font-family: 'Aboreto';

					@media screen and (max-width: $breakpoint-large) {
						font-size: 100px;
					}
					@media screen and (max-width: $breakpoint-medium) {
						font-size: 80px;
					}
					@media screen and (max-height: $breakpoint-tablet) {
						font-size: 65px;
					}
					@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
						display: none;
					}

					&Mobile {
						@extend .Header;
						display: none;
						@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
							display: block;
							font-size: 65px;
						}
						@media screen and (max-width: $breakpoint-mobile) {
							font-size: 50px;
						}
					}
				}
			}

			.Intro {
				position: fixed;
				left: 50%;
				width: 30%;
				top: 50vh;
				// transform: translateY(-50%);
				opacity: 1;
				transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);

				@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
					position: relative;
					margin-bottom: 20px;
					top: 0;
					left: 0;
					width: 100%;
				}

				&Scroll {
					@extend .Intro;
					top: 100vh;
					opacity: 0;
					@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
						top: 0;
						opacity: 1;
					}
				}
			}

			h2 {
				font-size: 35px;
				font-weight: 100;
				margin-bottom: 30px;
				@media screen and (max-width: $breakpoint-large) {
					font-size: 30px;
				}
				@media screen and (max-width: $breakpoint-medium) {
					font-size: 28px;
				}
				@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
					font-size: 26px;
				}
				@media screen and (max-height: $breakpoint-tablet) {
					font-size: 24px;
				}
				@media screen and (max-width: $breakpoint-mobile) {
					font-size: 22px;
				}
			}
			.GitHub {
				a {
					text-decoration: none;
					color: black;
					display: flex;
					justify-content: flex-start;
					// align-items: center;
					span {
						font-size: 30px;
						margin-right: 7px;
						@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
							font-size: 24px;
						}
					}
					p {
						vertical-align: middle;
						font-size: 24px;
						font-weight: 100;
						letter-spacing: 1pt;
						@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
							font-size: 20px;
						}
					}
				}
			}
		}

		.Projects {
			position: absolute;
			top: 80vh;
			height: fit-content;
			width: 100%;
			background-color: white;
			padding: 0 $padding-horizontal;
			// width: calc(100vw - 2 * $padding-horizontal);
			// margin: 0 $padding-horizontal;

			@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
				padding: 0;
				position: relative;
				top: 0;
			}
			.Title {
				position: relative;
				// margin: 50px 0 20px;
				height: 20vh;
				width: 100%;
				padding: 50px 10% 20px;
				@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
					background: linear-gradient($color-light, white);
				}
				display: flex;
				justify-content: space-between;
				align-items: center;
				// align-self: flex-start;

				.TitleLeft {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
					max-width: 70%;

					.IntroIcon {
						fill: none;
						stroke: black;
						stroke-width: 3;
						stroke-dasharray: 300;
						stroke-dashoffset: 300;
						transform: scale(0.3);
						// transition: all 1s ease 0.8s;

						@keyframes dashoffset {
							from {
								stroke-dashoffset: 300;
							}
							to {
								stroke-dashoffset: 0;
							}
						}
						&Appear {
							@extend .IntroIcon;
							animation: dashoffset 1.5s ease 0.5s forwards;
						}
						&Appear2 {
							@extend .IntroIcon;
							animation: dashoffset 1.5s ease 0.25s forwards;
						}
					}
					h2 {
						font-family: 'Aboreto';
						text-transform: uppercase;
						font-weight: 100;
						font-size: 30px;
						letter-spacing: 0.5pt;
						color: black;
						@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
							font-size: 24px;
						}
					}
					h3 {
						font-size: 22px;
						text-transform: uppercase;
						font-weight: 300;
						letter-spacing: 1pt;
						color: grey;
						margin: 5px 0;
						@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
							font-size: 18px;
						}
					}
				}
				.TitleRight {
					font-size: 40px;
					color: grey;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
						font-size: 36px;
					}
					p {
						margin-top: 5px;
						font-size: 14px;
						font-weight: 100;
						white-space: nowrap;
						color: black;
					}
				}

				&:last-of-type {
					margin-top: 5vh;
					margin-bottom: 0;
					background-color: $color-light;
					background: linear-gradient(white, $color-light);
					padding: 30px 10%;
				}
			}
			.DataVis {
				background: white;
				position: relative;
				// height: 50vh;
				height: fit-content;
				// padding-bottom: 50px;
				width: 100%;
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
					grid-template-columns: 100%;
					grid-template-rows: 50vh 50vh 50vh;
				}

				.DataVisProj {
					// animation: fadeInLift 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.175) 0.8s forwards;
					// background: linear-gradient(#afb0bc39, #b8ddbf3d);
					position: relative;
					background-color: white;
					height: 50vh;
					width: 100%;
					padding: 5%;
					cursor: pointer;
					// border: 1px solid black;
					display: grid;
					grid-template-rows: 80% 20%;
					transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);

					.DataVisImg {
						// position: absolute;
						// top: 50%;
						// left: 50%;
						// transform: translate(-50%, -50%);
						justify-self: center;
						align-self: center;
						max-width: 95%;
						max-height: 95%;
					}
					div {
						display: flex;
						flex-direction: column;
						justify-content: space-evenly;
						align-items: center;
						h3 {
							font-size: 24px;
							margin: 10px 0 5px;
							text-align: center;
							justify-self: center;
							align-self: center;
							font-family: 'Aboreto';
							text-transform: uppercase;
							font-weight: 100;
							letter-spacing: 0.5pt;
							color: grey;

							@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
								font-size: 22px;
							}
						}
						h4 {
							justify-self: center;
							align-self: center;
							font-size: 20px;
							text-transform: uppercase;
							font-weight: 300;
							letter-spacing: 1pt;
							color: black;
							margin: 5px 0;

							opacity: 0;
							transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);

							@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
								font-size: 18px;
								opacity: 1;
							}
						}
					}

					border-right: 1px dotted grey;
					&:last-of-type {
						border-right: none;
					}
					@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
						border-right: none;
						border-bottom: 1px dotted grey;
						&:last-of-type {
							border-bottom: none;
						}
					}

					&:hover {
						box-shadow: inset 0 0px 25px 5px rgba(128, 128, 128, 0.196);
						h4 {
							opacity: 1;
						}
					}
				}
			}
			.WebDev {
				position: relative;
				height: fit-content;
				width: 100%;
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-template-rows: 50vh 50vh;
				// background-color: white;

				@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
					grid-template-columns: 100%;
					grid-template-rows: 50vh 50vh 50vh 50vh;
				}
				@media screen and (max-height: 700px) {
					grid-template-rows: 400px 400px 400px 400px;
				}

				.WebDevProject {
					height: 100%;
					width: 100%;
					// opacity: 0;
					flex-shrink: 0;
					// margin: 30px;
					// border-radius: 20px;
					position: relative;
					transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
					@include flex-center;
					overflow: hidden;

					.Anchor {
						cursor: pointer;
						height: 100%;
						width: 100%;
						@include flex-center;
						.Description {
							position: absolute;
							top: 5%;
							right: 5%;
							display: flex;
							flex-direction: column;
							align-items: flex-end;
							h4 {
								text-transform: uppercase;
								font-weight: 300;
								letter-spacing: 1pt;
								color: grey;
								margin-bottom: 3px;
							}
							h3 {
								font-family: 'Aboreto';
								text-transform: uppercase;
								font-weight: 100;
								font-size: 20px;
								letter-spacing: 0.5pt;
								color: black;
							}
							p {
								font-weight: 100;
								font-size: 12px;
								letter-spacing: 0.5pt;
								font-style: italic;
								color: black;
							}
						}
						.Image {
							// z-index: 1;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							height: 60%;
							border-radius: 10px;
							transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
							@media screen and (max-width: 1200px) {
								height: auto;
								width: 80%;
							}
							@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
								height: 60%;
								width: auto;
							}
							@media screen and (max-width: 600px) {
								height: auto;
								width: 80%;
							}
							// @media screen and (max-width: 420px) {
							// 	top: 45%;
							// }
						}

						.Gif {
							// z-index: 2;
							display: none;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							height: 60%;
							width: auto;
							border-radius: 10px;
							transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
							@media screen and (max-width: 1200px) {
								height: auto;
								width: 80%;
							}
							// @media screen and (max-width: 420px) {
							// 	top: 45%;
							// }

							@keyframes playGif {
								0% {
									transform: translate(-50%, -50%) scale(1);
								}
								100% {
									transform: translate(-50%, -50%) scale(1.03);
								}
							}
							&Play {
								@extend .Gif;
								display: block;
								animation: playGif 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) forwards;
								@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
									display: none;
								}
							}
						}
					}

					.Info {
						position: absolute;
						top: 85%;
						height: 10%;
						width: 80%;
						// border: 1px solid black;
						display: flex;
						justify-content: center;
						// flex-wrap: wrap;
						// color: rgb(255, 255, 255);

						@media screen and (max-width: 420px) {
							top: 72%;
							flex-wrap: wrap;
							width: 100%;
							height: 25%;
							align-items: center;
						}

						.Icon {
							margin: 0 2%;
							@media screen and (max-width: 420px) {
								margin: 5px 2%;
							}
							width: 60px;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							font-size: 22px;
							color: rgb(100, 100, 100);
							transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);

							p {
								margin-top: 5px;
								font-size: 12px;
								font-weight: 100;
								white-space: nowrap;
							}
						}
					}

					&:hover {
						box-shadow: inset 0 0px 25px 5px rgba(128, 128, 128, 0.196);

						.Icon {
							color: black;
						}
					}
					&1 {
						@extend .WebDevProject;
						animation: fadeInLift 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.175) 0.8s forwards;
						background: linear-gradient(#a4a9dc39, #ddb8dc3d);
					}
					&2 {
						@extend .WebDevProject;
						animation: fadeInLift 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.175) 1s forwards;
						background: linear-gradient(#dcd8cd52, #dab3ae52);
					}
					&3 {
						@extend .WebDevProject;
						animation: fadeInLift 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.175) 1.2s forwards;
						background: linear-gradient(#cbd2b860, #71b8d44d);
					}
					&4 {
						@extend .WebDevProject;
						animation: fadeInLift 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.175) 1.4s forwards;
						background: linear-gradient(#d2c8a448, #afb9e153);
					}
				}
			}
		}
		.Links {
			position: absolute;
			bottom: 100px;
			left: 50%;
			transform: translateX(-50%);

			@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
				position: relative;
				top: 0;
				width: 100%;
				left: 0;
				@include flex-center;
				transform: none;
				padding: 30px 0;
			}
			@media screen and (max-height: $breakpoint-tablet) {
				bottom: 20px;
			}

			&Hide {
				@extend .Links;
				display: none;
				@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
					display: flex;
				}
			}
		}
		.BottomBackground {
			width: 100%;
			height: 50vh;
			position: absolute;
			bottom: 0;
			background-color: white;
			// background-color: #ffffff;
			z-index: -1;

			@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
				display: none;
			}
		}
	}
}
