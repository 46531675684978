@import 'sass';

.Container {
	width: 300px;
	height: 80px;
	border: 2px solid black;
	margin: 10px auto 10px;
	z-index: 1;
	position: relative;

	@media screen and (max-width: $breakpoint-desktop) {
		width: 250px;
		height: 80px;
	}
	@media screen and (max-width: $breakpoint-large) {
		width: 230px;
		height: 75px;
	}
	@media screen and (max-width: $breakpoint-medium) {
		width: 200px;
		height: 60px;
	}
	@media screen and (max-width: $breakpoint-mobile), (max-height: $breakpoint-tablet) {
		width: 150px;
		height: 60px;
	}

	.Inner {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 0;

		color: black;
		font-size: 30px;
		font-weight: 200;
		text-transform: uppercase;
		letter-spacing: 1px;
		white-space: nowrap;
		padding: 6% 12%;

		@media screen and (max-width: $breakpoint-desktop) {
			font-size: 25px;
		}
		@media screen and (max-width: $breakpoint-large) {
			font-size: 23px;
		}
		@media screen and (max-width: $breakpoint-medium) {
			font-size: 20px;
		}
		@media screen and (max-width: $breakpoint-mobile), (max-height: $breakpoint-mobile) {
			font-size: 20px;
		}

		&::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: -1;

			background-color: rgba($color: $color-fitness, $alpha: 1);
			top: 7px;
			left: 7px;
			transition: 0.2s;
		}

		&:hover::after {
			top: 0px;
			left: 0px;
		}
		&:active::after {
			top: 0px;
			left: 0px;
		}
	}
}
