@import 'sass';

.Scroll {
	position: fixed;
	color: black;
	bottom: 10%;
	left: 8%;
	cursor: default;
	z-index: 10;
	@include flex-center;
	flex-direction: column;
	transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
	opacity: 0;
	animation: appear 1s 0.5s forwards;

	p {
		transform: rotate(-90deg);
		font-weight: 300;
		font-size: 12px;
		margin-bottom: 15px;
	}

	.Arrow {
		@keyframes bounce {
			from {
				transform: translateY(0);
			}
			60% {
				transform: translateY(15px);
			}
			to {
				transform: translateY(0);
			}
		}
		animation: bounce 1.5s infinite;
	}

	&Bottom {
		@extend .Scroll;
		animation: disappear 2s forwards;
	}
}
