@import 'sass';

.FitnessPage {
	width: 100%;
	@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
		height: fit-content;
	}
	height: 1900px;
	position: absolute;
	top: 0;
	z-index: 1;
	overflow-x: hidden;

	&Loading {
		@extend .FitnessPage;
		height: 100vh;
	}

	@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
		background-color: $color-fitness;
		height: fit-content;
		min-height: 100vh;
	}

	.Top {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 100%;
		z-index: -9;
		background-color: $color-light;
		@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
			display: none;
		}
	}
	.Bottom {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: $color-fitness;
		z-index: -9;
		@include flex-center;

		@keyframes bottomSlideIn {
			from {
				top: 0;
				height: 100%;
				// background: linear-gradient($color-fitness, #f3f3f3);
			}
			to {
				top: 50vh;
				height: 50%;
				// background: $color-fitness;
			}
		}
		@keyframes bottomSlideUp {
			from {
				top: 50vh;
				// background: $color-fitness;
			}
			to {
				top: 0;
				// background: linear-gradient($color-fitness, #f3f3f3);
			}
		}

		&Top {
			@extend .Bottom;
			@media screen and (min-width: $breakpoint-tablet) and (min-height: $breakpoint-mobile-landscape) {
				animation: bottomSlideIn 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s 1 normal forwards;
			}
		}

		&Scroll {
			@extend .Bottom;
			@media screen and (min-width: $breakpoint-tablet) and (min-height: $breakpoint-mobile-landscape) {
				animation: bottomSlideUp 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0s 1 normal forwards;
			}
		}
	}
	.LoadingPage {
		position: absolute;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		@include flex-center;
		opacity: 1;
		transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

		&Hide {
			@extend .LoadingPage;
			animation: fadeThenLeave 0.5s ease forwards;
			@keyframes fadeThenLeave {
				0% {
					opacity: 1;
					transform: translateX(0);
				}
				99% {
					opacity: 0;
					transform: translateX(0);
				}
				100% {
					opacity: 0;
					transform: translateX(-100vw);
				}
			}
		}
	}

	.Main {
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		opacity: 0;
		animation: fadeInLift 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

		@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
			width: 100%;
			height: fit-content;
			position: relative;
			padding-top: 7vh;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
		}

		&Loading {
			@extend .Main;
			display: none;
		}

		.Fitness {
			position: fixed;
			height: 100vh;
			width: 100vw;
			top: 0;
			left: 0;
			transition: all 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
			// opacity: 0;
			// animation: fadeInLift 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

			@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
				position: relative;
				height: fit-content;
				width: 100%;
				top: 0;
				right: 0;
				padding: 10%;
			}

			.Title {
				position: absolute;
				left: 12%;
				bottom: 57vh;
				opacity: 1;
				transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
				font-size: 100px;
				font-weight: normal;
				font-family: 'Aboreto';
				color: $color-headers;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;

				@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
					position: relative;
					margin-bottom: 40px;
					top: 0;
					left: 0;
				}

				&Scroll {
					@extend .Title;
					bottom: 100vh;
					opacity: 0;
					@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
						top: 0;
						opacity: 1;
					}
				}

				.WDIcon {
					fill: none;
					stroke: black;
					stroke-width: 4;
					stroke-dasharray: 300;
					stroke-dashoffset: 300;
					transform: scale(0.5);
					// transition: all 1.5s ease 0.5s;

					@keyframes dashoffset {
						from {
							stroke-dashoffset: 300;
						}
						to {
							stroke-dashoffset: 0;
						}
					}

					animation: dashoffset 1.5s ease 0.5s forwards;
				}

				.Header {
					font-family: 'Aboreto';

					@media screen and (max-width: $breakpoint-desktop) {
						font-size: 95px;
					}
					@media screen and (max-width: $breakpoint-large) {
						font-size: 85px;
					}
					@media screen and (max-width: $breakpoint-medium) {
						font-size: 65px;
					}
					@media screen and (max-height: $breakpoint-tablet) {
						font-size: 65px;
					}
					@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
						font-size: 60px;
					}
					@media screen and (max-width: $breakpoint-mobile) {
						font-size: 45px;
					}
				}
			}

			.Intro {
				position: fixed;
				width: 30vw;
				left: 12vw;
				top: 55vh;
				opacity: 1;
				transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);

				@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
					position: relative;
					margin-bottom: 20px;
					top: 0;
					left: 0;
					width: 100%;
				}

				&Scroll {
					@extend .Intro;
					top: 100vh;
					opacity: 0;
					@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
						top: 0;
						opacity: 1;
					}
				}
			}

			h2 {
				font-size: 35px;
				font-weight: 100;
				margin-bottom: 30px;
				@media screen and (max-width: $breakpoint-large) {
					font-size: 30px;
				}
				@media screen and (max-width: $breakpoint-medium) {
					font-size: 28px;
				}
				@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
					font-size: 26px;
				}
				@media screen and (max-height: $breakpoint-tablet) {
					font-size: 24px;
				}
				@media screen and (max-width: $breakpoint-mobile) {
					font-size: 22px;
				}
			}
			p {
				font-size: 24px;
				font-weight: 100;
				letter-spacing: 1pt;
				margin-bottom: 20px;
				@media screen and (max-width: $breakpoint-desktop) {
					font-size: 22px;
				}
				@media screen and (max-width: $breakpoint-medium) {
					font-size: 20px;
				}
				@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
					font-size: 18px;
				}
				@media screen and (max-height: $breakpoint-tablet) {
					font-size: 16px;
				}
				@media screen and (max-width: $breakpoint-mobile) {
					font-size: 14px;
				}
			}
		}

		.Running {
			position: fixed;
			height: 50vh;
			width: 47vw;
			// right: $padding-horizontal;
			left: 50vw;
			top: 50vh;
			opacity: 1;
			transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
			overflow: hidden;

			@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
				position: relative;
				top: 0;
				left: 0;
				height: fit-content;
				width: 100%;
				overflow: visible;
				margin-bottom: 20px;
			}

			&Scroll {
				@extend .Running;
				top: 100vh;
				opacity: 0;
				@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
					top: 0;
					opacity: 1;
				}
			}

			img {
				position: absolute;
				top: 0;
				right: 0;
				height: 100%;
				// width: 100%;

				@media screen and (max-width: 1500px) {
					right: 50%;
					transform: translateX(50%);
					height: 100%;
				}

				@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
					position: relative;
					height: auto;
					width: 100%;
					right: 0;
					transform: none;
				}
			}
		}

		.Testimonials {
			position: absolute;
			top: 100px;
			width: 100%;
			transition: all 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s;
			opacity: 1;
			padding: 50px 150px;
			@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
				position: relative;
				top: 0;
				padding: 50px;
				margin-bottom: 20px;
				min-height: fit-content;
			}

			.Clients {
				position: relative;
				width: 100%;
				height: 500px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
					height: fit-content;
				}

				article {
					position: relative;
					padding: 50px 10px;
					text-align: justify;
					@include flex-center;
					font-size: 16px;
					font-weight: 100;
					max-height: 100%;
					@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
						margin-bottom: 30px;
						min-height: 400px;
					}
					@media screen and (max-width: $breakpoint-mobile) {
						max-height: 700px;
					}

					p {
						width: 100%;
						max-height: 100%;
						font-weight: 100;
						@media screen and (max-width: $breakpoint-mobile) {
							max-height: 600px;
							font-weight: 300;
						}
						overflow-y: auto;
						br {
							display: block; /* makes it have a width */
							content: ''; /* clears default height */
							margin-top: 10px; /* change this to whatever height you want it */
						}
					}

					@media screen and (min-width: $breakpoint-tablet) and (min-height: $breakpoint-mobile-landscape) {
						display: flex;
						position: absolute;
						top: 225px;
						left: 50%;
						transform: translateX(-50%);
						height: 380px;
						width: 50%;
						padding: 20px 50px;
						text-align: justify;
						@include flex-center;
						font-size: 16px;
						font-weight: 100;
					}
					@media screen and (max-width: $breakpoint-large) {
						padding: 50px 10px;
					}
					@media screen and (min-width: $breakpoint-medium) {
						width: 55%;
						top: 225px;
						height: 400px;
					}
					@media screen and (min-width: $breakpoint-large) {
						top: 275px;
					}
					@media screen and (min-width: 1600px) {
						width: 60%;
					}

					.TestimonialsHeaderHover {
						width: 100%;
						max-height: 100%;
						@include flex-center;
						font-family: 'Aboreto';
						font-size: 50px;
						font-weight: 300;
						text-align: center;
						@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
							display: none;
						}

						&Hide {
							@extend .TestimonialsHeaderHover;
							display: none;
						}
					}
					.TestimonialsHeaderTap {
						display: none;
						@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
							width: 100%;
							max-height: 100%;
							@include flex-center;
							font-family: 'Aboreto';
							font-size: 40px;
							font-weight: 300;
							text-align: center;
						}
						&Hide {
							@extend .TestimonialsHeaderTap;
							display: none;
						}
					}

					.Text {
						display: none;
						&Current {
							@extend .Text;
							display: block;
							opacity: 0;
							animation: appear 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) forwards;
						}
					}

					.Left {
						position: absolute;
						top: 0;
						left: 0;
						font-size: 40px;
						color: #aaaaaa;
					}
					.Right {
						position: absolute;
						bottom: 0;
						right: 0;
						font-size: 40px;
						color: #aaaaaa;
					}
				}

				.ClientAvatars {
					@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
						display: flex;
						justify-content: center;
						flex-wrap: wrap;
						// overflow-x: auto;
					}
					.Client {
						border-color: rgba(255, 255, 255, 0);
						border-radius: 50%;
						overflow: hidden;
						border: none;
						box-shadow: 0 10px 20px 5px #80808051;
						margin: 10px;

						flex-shrink: 0;
						// box-sizing: content-box;
						// border: 2px solid;

						height: 100px;
						@media screen and (min-width: $breakpoint-tablet) and (min-height: $breakpoint-mobile-landscape) {
							height: 120px;
						}
						@media screen and (min-width: $breakpoint-medium) {
							height: 150px;
						}
						@media screen and (min-width: $breakpoint-large) {
							height: 200px;
						}

						img {
							height: 100%;
						}
						.Ayaka {
							border-radius: 50%;
						}

						&::after {
							height: 100%;
							width: 100%;
							position: absolute;
							top: 100%;
							left: 0;
							@include flex-center;
							font-family: 'Aboreto';
							font-size: 26px;
							@media screen and (max-width: $breakpoint-tablet),
								(max-height: $breakpoint-mobile-landscape) {
								font-size: 18px;
							}

							font-weight: 300;
							background: rgba($color: #ffffff, $alpha: 0.6);
							transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
						}
						&:hover::after {
							top: 0;
						}
						&:active::after {
							top: 0;
						}

						&Andrew {
							@extend .Client;
							position: relative;
							&::after {
								content: 'Andrew';
							}
							@media screen and (min-width: $breakpoint-tablet) and (min-height: $breakpoint-mobile-landscape) {
								position: absolute;
								top: 50px;
								left: 25%;
							}
						}
						&Sara {
							@extend .Client;
							position: relative;
							&::after {
								content: 'Sara';
							}
							@media screen and (min-width: $breakpoint-tablet) and (min-height: $breakpoint-mobile-landscape) {
								position: absolute;
								top: 50px;
								right: 25%;
							}
						}
						&Vijay {
							@extend .Client;
							position: relative;
							&::after {
								content: 'Vijay';
							}
							@media screen and (min-width: $breakpoint-tablet) and (min-height: $breakpoint-mobile-landscape) {
								position: absolute;
								top: 600px;
								left: 0;
							}
						}
						&Chet {
							@extend .Client;
							position: relative;
							&::after {
								content: 'Chet';
							}
							@media screen and (min-width: $breakpoint-tablet) and (min-height: $breakpoint-mobile-landscape) {
								position: absolute;
								top: 300px;
								right: 0;
							}
						}
						&Ayaka {
							@extend .Client;
							position: relative;
							&::after {
								content: 'Ayaka';
							}
							@media screen and (min-width: $breakpoint-tablet) and (min-height: $breakpoint-mobile-landscape) {
								position: absolute;
								top: 300px;
								left: 0;
							}
						}
						&Cayley {
							@extend .Client;
							position: relative;
							&::after {
								content: 'Cayley';
							}
							@media screen and (min-width: $breakpoint-tablet) and (min-height: $breakpoint-mobile-landscape) {
								position: absolute;
								top: 600px;
								right: 0;
							}
						}
						&Joanna {
							@extend .Client;
							position: relative;
							&::after {
								content: 'Joanna';
							}
							@media screen and (min-width: $breakpoint-tablet) and (min-height: $breakpoint-mobile-landscape) {
								position: absolute;
								top: 725px;
								left: 25%;
							}
						}
						&AndrewH {
							@extend .Client;
							position: relative;
							&::after {
								content: 'Andrew';
							}
							@media screen and (min-width: $breakpoint-tablet) and (min-height: $breakpoint-mobile-landscape) {
								position: absolute;
								top: 725px;
								right: 25%;
							}
						}
					}
				}
			}

			&Hide {
				@extend .Testimonials;
				opacity: 0;
				transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
				@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
					opacity: 1;
				}
			}
		}

		.Consultation {
			position: absolute;
			top: 1150px;
			height: 400px;
			width: 100%;
			transition: all 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s;
			opacity: 1;
			padding: 50px 15%;
			@include flex-center;
			flex-direction: column;
			// border: 1px solid black;
			background: linear-gradient($color-fitness 0%, #ffffff 5%, #ffffff 95%, $color-fitness 100%);
			font-size: 35px;
			font-weight: 100;
			margin-bottom: 30px;
			text-align: center;
			@media screen and (max-width: $breakpoint-large) {
				font-size: 30px;
			}
			@media screen and (max-width: $breakpoint-medium) {
				font-size: 28px;
			}
			@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
				font-size: 26px;
			}
			@media screen and (max-height: $breakpoint-tablet) {
				font-size: 24px;
			}
			@media screen and (max-width: $breakpoint-mobile) {
				font-size: 20px;
			}

			@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
				position: relative;
				top: 0;
				padding: 50px;
				margin-bottom: 20px;
				height: 300px;
			}

			.ConsultationText {
				margin-bottom: 50px;
				@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
					margin-bottom: 25px;
				}
			}

			&Hide {
				@extend .Consultation;
				opacity: 0;
				transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
				@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
					opacity: 1;
				}
			}
		}

		.Links {
			position: absolute;
			top: 1600px;
			left: 50%;
			transform: translateX(-50%);

			@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
				position: relative;
				top: 0;
				width: 100%;
				left: 0;
				@include flex-center;
				transform: none;
				// margin: 30px 0;
				padding: 30px 0;
				// margin-bottom: 200px;
				// height: auto;
				// &::before {
				// 	content: '';
				// 	height: 30px;
				// }
				// &::after {
				// 	content: '';
				// 	height: 30px;
				// }
			}

			&Hide {
				@extend .Links;
				display: none;
				@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
					display: flex;
				}
			}
		}
	}
	.ScrollArrow {
		position: fixed;
		@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile-landscape) {
			display: none;
		}
	}
}
