@import 'sass';

.FormContainer {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	form {
		width: 70%;
		height: 85%;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: flex-start;

		@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
			position: relative;
			width: 100%;
			height: 100%;
		}

		@mixin input {
			height: 40px;
			border: 1px solid $color-contact;
			@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
				border: 1px solid $color-light;
			}
			padding: 5px 2% 3px;
			font-size: 16px;
			letter-spacing: 1pt;
			font-weight: 300;
			// text-transform: uppercase;
			overflow: hidden;
			white-space: wrap;
			background-color: white;
			border-radius: 0;

			// ::placeholder {
			// 	color: #999999;
			// }
		}
		@mixin label {
			position: absolute;
			bottom: 102%;
			left: 0;
			font-size: 11px;
			letter-spacing: 0.5pt;
			color: rgb(80, 80, 80);
			@media screen and (max-height: 860px) {
				display: none;
			}
			@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
				display: block;
				color: black;
			}
		}

		.Name {
			position: relative;
			width: 100%;
			display: flex;
			justify-content: space-between;

			.NameLabel {
				@include label();
			}

			input {
				@include input();
				width: 47.5%;
			}
		}

		.Select {
			width: 100%;
			display: flex;
			justify-content: space-between;
			position: relative;

			.SelectLabel {
				@include label();
			}

			.Topic {
				@include input();
				position: relative;
				width: 30%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				z-index: 0;
				color: rgb(130, 130, 130);
				transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
				cursor: pointer;

				&Selected {
					@extend .Topic;
					z-index: 1;
					color: black;
					// color: black;
					background-color: #d0d0d0;
					// background-color: rgba($color: $color-contact, $alpha: 0.5);
				}
			}

			.SelectOne {
				color: rgb(110, 110, 110);
				font-weight: 300;
				position: relative;
			}
		}
		.Email {
			position: relative;
			width: 100%;
			display: flex;
			justify-content: flex-start;

			.EmailLabel {
				@include label();
			}

			input {
				@include input();
				width: 100%;
			}
		}
		.Message {
			position: relative;
			width: 100%;
			display: flex;
			justify-content: flex-start;

			.MessageLabel {
				@include label();
				bottom: 101%;
			}

			textarea {
				@include input();
				padding: 10px 2%;
				width: 100%;
				height: 80px;
			}
		}
		.Submit {
			width: 100%;
			position: relative;
			display: flex;
			justify-content: space-between;

			button {
				@include input();
				padding: 0 0 0;
				justify-content: center;
				width: 30%;
				position: relative;
				z-index: 1;

				.SlideButton {
					height: 100%;
					width: 100%;
					@include flex-center;
					transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
					color: black;
					&::after {
						content: '';
						z-index: -1;
						position: absolute;
						height: 100%;
						width: 100%;
						top: 0;
						left: 5px;
						left: -100%;
						background: rgba($color: #bebebe, $alpha: 1);
						transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
					}
					&:hover::after {
						left: 0;
					}

					&Pending {
						@extend .SlideButton;
						&::after {
							left: 0;
						}
					}

					.Ellipsis {
						position: absolute;
						top: 20%;
						left: 0;
						height: 10%;
						width: 100%;
						@include flex-center;
						font-size: 40px;
						font-weight: 700;

						@keyframes ellipsis {
							0% {
								opacity: 0;
							}
							40% {
								opacity: 1;
							}
							60% {
								opacity: 1;
							}
							100% {
								opacity: 0;
							}
						}

						.One {
							animation: ellipsis 1s ease-in-out 0s infinite;
						}
						.Two {
							animation: ellipsis 1s ease-in-out 0.3s infinite;
						}
						.Three {
							animation: ellipsis 1s ease-in-out 0.6s infinite;
						}
					}
				}
			}
			.Submitted {
				width: 60%;
				height: 100%;
				display: flex;
				align-items: center;
				opacity: 0;
				animation: fadeInLift 1s forwards;

				p {
					font-family: 'Aboreto';
					font-size: 18px;
					letter-spacing: 1pt;
					font-weight: 300;
				}
			}
		}
	}
}
