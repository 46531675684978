@import 'sass';

.Contact {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	z-index: 1;
	overflow-x: hidden;
	background-color: $color-light;

	&Loading {
		@extend .Contact;
		height: 100vh;
	}

	@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
		position: absolute;
		background-color: $color-contact;
		height: fit-content;
		min-height: 100vh;
	}

	.OpenScreen {
		position: fixed;
		bottom: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: $color-contact;
		z-index: -9;
		@include flex-center;

		@keyframes ScreenSlideCorner {
			from {
				height: 100%;
				width: 100%;
			}
			to {
				height: 50%;
				width: 50%;
			}
		}
		@keyframes ScreenSlideDown {
			from {
				height: 100%;
			}
			to {
				height: 0;
			}
		}

		&Loaded {
			@extend .OpenScreen;
			animation: ScreenSlideCorner 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s forwards;
			@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
				animation: ScreenSlideDown 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s forwards;
			}
		}
	}
	.LoadingPage {
		position: absolute;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		@include flex-center;
		opacity: 1;
		transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

		&Hide {
			@extend .LoadingPage;
			animation: fadeThenLeave 0.5s ease forwards;
			@keyframes fadeThenLeave {
				0% {
					opacity: 1;
					transform: translateX(0);
				}
				99% {
					opacity: 0;
					transform: translateX(0);
				}
				100% {
					opacity: 0;
					transform: translateX(-100vw);
				}
			}
		}
	}
	.Main {
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		opacity: 0;
		animation: fadeInLift 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

		@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
			width: 100%;
			height: fit-content;
			position: relative;
			padding-top: 7vh;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
		}

		&Loading {
			@extend .Main;
			display: none;
		}

		.Work {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			transition: all 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
			// opacity: 0;
			// animation: fadeInLift 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

			@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
				position: relative;
				height: fit-content;
				width: 100%;
				justify-content: flex-start;
				top: 0;
				right: 0;
				padding: 10%;
			}

			.Title {
				z-index: 3;
				position: absolute;
				left: 15vw;
				top: 50vh;
				transform: translateY(-65%);
				opacity: 1;
				transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
				font-size: 100px;
				font-weight: normal;
				font-family: 'Aboreto';
				color: $color-headers;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;

				@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
					position: relative;
					margin-bottom: 20px;
					top: 0;
					left: 0;
					transform: none;
				}

				.Icon {
					fill: none;
					stroke: black;
					stroke-width: 4;
					stroke-dasharray: 300;
					stroke-dashoffset: 300;
					transform: scale(0.5);
					// transition: all 1.5s ease 0.5s;

					@keyframes dashoffset {
						from {
							stroke-dashoffset: 300;
						}
						to {
							stroke-dashoffset: 0;
						}
					}

					animation: dashoffset 1.5s ease 0.5s forwards;
				}

				.Header {
					font-family: 'Aboreto';

					@media screen and (max-width: $breakpoint-large) {
						font-size: 100px;
					}
					@media screen and (max-width: $breakpoint-medium) {
						font-size: 80px;
					}
					@media screen and (max-height: $breakpoint-tablet) {
						font-size: 65px;
					}
					@media screen and (max-width: $breakpoint-tablet) {
						font-size: 65px;
					}
					@media screen and (max-width: $breakpoint-mobile) {
						font-size: 50px;
					}

					&Mobile {
						@extend .Header;
						display: none;
					}
				}
			}
		}
		.ContactForm {
			position: absolute;
			height: 50%;
			width: 0%;
			top: 50%;
			left: 50%;
			background: $color-alternate;
			@include flex-center;
			overflow: hidden;
			// z-index: -4;

			// transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
			animation: RollOutRight 0.6s cubic-bezier(0.86, 0, 0.07, 1) 1s forwards;

			@keyframes RollOutRight {
				from {
					width: 0%;
				}
				to {
					width: 50%;
				}
			}

			@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
				position: relative;
				margin-bottom: 20px;
				top: 0;
				left: 0;
				width: 100%;
				height: 350px;
				padding: 0 10%;
				animation: none;
				background: none;
			}

			.FormDiv {
				height: 100%;
				width: 100%;
				padding: 0 $padding-horizontal $padding-vertical 0;
				@include flex-center;
				opacity: 0;
				animation: appear 0.3s ease-in 1.5s forwards;
				@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
					padding: 0 0 0 0;
					animation: none;
					opacity: 1;
				}
			}
		}
		.ContactInfo {
			position: absolute;
			top: 50%;
			left: 0;
			height: 50%;
			width: 50%;
			padding: 0 0 $padding-vertical $padding-horizontal;

			@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
				position: relative;
				@include flex-center;
				top: 0;
				left: 0;
				width: 100%;
				height: 200px;
				padding: 0 10%;
				animation: none;
			}

			.InfoDiv {
				position: absolute;
				left: 16vw;
				top: 35%;
				height: fit-content;
				width: 300px;
				padding: 0 $padding-horizontal $padding-vertical 0;
				font-size: 25px;
				font-weight: 100;
				color: $color-alternate;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				align-items: flex-start;

				// p {
				// 	text-align: left;
				// }

				opacity: 0;
				animation: fadeInLift 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.75s forwards;
				@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
					position: relative;
					top: 0;
					left: 0;
					padding: 0 0 0 0;
					animation: none;
					opacity: 1;
					width: 100%;
					font-size: 25px;
				}

				.ContactIcons {
					margin-top: 30px;
					font-size: 30px;
					color: black;
					width: 80px;
					display: flex;
					justify-content: space-between;
					@media screen and (max-width: $breakpoint-tablet), (max-height: $breakpoint-tablet) {
						font-size: 25px;
						width: 60px;
					}

					a {
						color: black;
						text-decoration: none;
						transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
						&:hover {
							color: $color-alternate;
						}
					}
				}
			}
		}
	}
}
