* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
}

body {
  max-width: 100%;
  overflow-x: hidden;
}

body, html {
  height: 100%;
  width: 100%;
  margin: 0;
}

/* ------------------------------DATA VIS------------------------------ */

.main-container {
  height: 100%;
  min-height: fit-content;
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* overflow: auto; */
}

/* ------------------------------DINO TREE------------------------------ */

#dino-tree-container {
  width: fit-content;
  height: fit-content;
  position: relative;
  align-self: flex-start;
  /* display: flex;
  justify-content: flex-start; */
}

.node-label {
  font-style: italic;
  font-size: 11px;
  letter-spacing: 0.3pt;
  /* font-weight: 300; */
}

.era-text {
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 1pt;
  fill: black;
  text-transform: capitalize;
}
.sub-era-text {
  font-size: 10px;
  font-weight: 100;
  letter-spacing: 0.75pt;
  fill: black;
  text-transform: capitalize;
}

.axis-label {
  font-size: 10px;
  /* font-weight: 300; */
  letter-spacing: 0.25pt;
  fill: black;
}

.key {
  letter-spacing: 0.5pt;
  font-weight: 300;
  font-size: 14px;
}

/* ------------------------------NBA BAR CHART RACE------------------------------ */

#bar-chart-container {
  width: fit-content;
  height: fit-content;
  max-width: 100%;
  margin: 10px 0;
  position: relative;
  display: flex;
  justify-content: center;
  }


#nba-chart {
  display: block;
  /* width: 100%; */
  /* max-width: 95%; */
  max-height: min-content;
  height: 900px;
  /* display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; */
  @media screen and (max-width: 600px) {
    max-height: 80vh;
  }
}

.champ-text {
  letter-spacing: 1.5pt;
  font-style: italic;
  font-weight: 100;
  font-size: 12px;
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  fill: grey;
}

.tooltip {
  width: 160px;
  height: fit-content;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
}

.tooltip h4 {
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 0.75pt;
  color: black;
  margin: 2px 0;
}

#info-display {
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 1pt;
}
#info-display-2 {
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 1pt;
  fill: grey;
}

#title {
  font-size: 20px;
  font-weight: 300;
  width: 600px;
  max-width: 100%;
  letter-spacing: 1pt;
  text-align: left;
  color: black;
  margin: 5px 0;
  padding: 0 10px;
  @media screen and (max-width: 500px) {
    margin: 25px 0 0;
  }
}
#subtitle {
  font-size: 14px;
  font-weight: 100;
  font-style: italic;
  width: 600px;
  max-width: 100%;
  letter-spacing: 1pt;
  text-align: left;
  color: grey;
  /* margin: 5px 0; */
  padding: 0 10px;
}

#head {
  height: 50px;
  width: 600px;
  max-width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  margin: 15px 0 5px;
  padding: 0 10px;
}

#year {
  font-size: 50px;
  font-weight: 300;
  letter-spacing: 2pt;
  text-align: center;
  color: black;
  margin: 5px 0;
  /* display: none; */
}

#controls {
  height: 100%;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 5px 0;
}

@keyframes bounce {
  0% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(20px);
  }
  100% {
      transform: translateY(0);
  }
}

.bounce {
  animation: bounce 1.2s infinite;
}

#play-button {
  position: absolute;
  margin: 0 10px;
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-weight: 700;
  letter-spacing: 1pt;
  color: white;
  font-size: 14px;
  cursor: pointer;
  background: none;
  transition: all 0.3s ease-in-out;
}

#play-button:active {
  opacity: 0.5;
}

.play-button-disabled {
  opacity: 0.5;
  cursor: default;
}

#play-button::before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-image: url('https://s3.us-west-1.amazonaws.com/dalton.d3/nba_bar_chart_race/basketball-ball.png');
  background-position: center;
  background-size: contain;
}

#speed {
  margin: 0 10px;
  width: 100px;
  /* height: 80%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#speed-input {
  margin: 5px 0;
  /* width: 100px; */
}
#speed-label {
  margin: 5px 0;
  font-size: 12px;
  font-weight: 100;
}

/* ------------------------------GEO SUNBURST------------------------------ */
#sunburst-container {
  width: 100vh;
  max-height: 100vh;
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

#sort-label {
  font-size: 16px;
  margin-top: 10px;
  font-weight: 100;
  letter-spacing: 1pt;
}
#sort-select {
  margin: 5px 0;
}

#tooltip-sunburst {
  width: fit-content;
  height: fit-content;
  background-color: rgb(221, 221, 221);
  padding: 3px 5px;
  box-shadow: 0 0 2px rgb(130, 130, 130);
  z-index: 100;
}

#tooltip-sunburst h4 {
  font-size: 12px;
  font-weight: 300;
  color: black;
  letter-spacing: 0.5pt;
}

.back-cursor {
  /* cursor: url('./content/back.png'); */
  cursor: url('./assets/back.png'), auto;
  /* cursor: pointer; */
}